<template>
  <data-list
    ref="dataList"
    class="content md"
    url="/admin/record-keep"
    entity-name="设备"
    add-btn="添加设备"
    hide-show-detail
    show-row-num
    :action="action"
    :rules="rules"
    :columns="columns"
    :before-edit="beforeEdit"
    :before-update="beforeUpdate"
    :modal-width="640"
    :default-form="defaultForm"
    :modal-form="{layout: 'horizontal', labelAlign: 'left', labelCol: {span: 4}, wrapperCol: {span: 20}}"
    :search-key-type="{name: 1}">
    <template v-slot:query="{form}">
      <a-form-model-item label="">
        <a-input-search v-model="form.name" placeholder="输入设备名称查询"></a-input-search>
      </a-form-model-item>
    </template>
    <template v-slot:edit="{form}">
      <a-form-model-item label="设备名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入设备名称"></a-input>
      </a-form-model-item>
      <a-form-model-item label="维保次数" prop="type">
        <a-select
          style="width: 120px"
          placeholder="请选择"
          :options="typeOptions"
          v-model="form.type">
        </a-select>
        <span style="margin-left: 8px">1次</span>
      </a-form-model-item>
      <a-form-model-item label="提醒人" prop="user">
        <select-book multiple type="user" v-model="form.user"></select-book>
      </a-form-model-item>
      <a-form-model-item label="维修提醒" prop="week_time">
        <span>每个周期第</span>
        <a-input-number :min="1" :max="getMax(form.type)" v-model="form.week_time" placeholder="请输入" style="margin: 0 8px"></a-input-number>
        <span>天</span>
        <a-time-picker format="HH:mm" value-format="HH:mm" :allow-clear="false" v-model="form.remind_time" style="margin: 0 8px"></a-time-picker>
        <span>提醒</span>
      </a-form-model-item>
      <a-form-model-item label="未完成提醒">
        <span>维修未完成时，每个周期倒数前3天</span>
        <a-time-picker format="HH:mm" value-format="HH:mm" :allow-clear="false" v-model="form.unfinished_remind" style="margin: 0 8px"></a-time-picker>
        <span>提醒，每天提醒一次，共提醒3次</span>
      </a-form-model-item>
      <a-form-model-item label="提醒方式" prop="methods">
        <a-checkbox-group v-model="form.methods" :options="methodOptions" />
      </a-form-model-item>
  </template>
</data-list>
</template>

<script>
import {getMaintenanceTypeText, maintenanceTypeOptions} from "@/views/program/data/maintenance";


    export default {
        name: "Maintenance",
        data() {
            return {
                columns: [
                    { title: '设备名称', dataIndex: 'name' },
                    { title: '创建时间', dataIndex: 'create_time' },
                    { title: '维保次数', dataIndex: 'type', customRender: getMaintenanceTypeText },
                ],
                typeOptions: maintenanceTypeOptions,
                rules: {
                    name: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
                    type: [{ required: true, message: '请选择维保次数', trigger: 'change' }],
                    user: [{ required: true, message: '请选择提醒人', trigger: 'change' }],
                    week_time: [{ required: true, message: "请选择维修提醒", trigger: "change" }],
                    methods: [{ required: true, message: "请选择提醒方式", trigger: "change" }],
                },
                methodOptions: [
                    { label: "企业微信通知", value: 1 },
                    { label: "短信通知", value: 2 },
                    { label: "语音电话通知", value: 3 },
                ],
                defaultForm: {
                    type: 1,
                    week_time: 1,
                    remind_time: "12:00",
                    unfinished_remind: "12:00",
                }
            }
        },
        computed: {
            action() {
                return this.$route.meta.action;
            },
        },
        methods: {
            getMax(type) {
                return [13, 28, 59, 89, 180][type - 1];
            },
            beforeEdit(form, callback) {
                if(form.id) {
                    form.user = form.user_ids.split(",");
                    form.methods = form.remind_method.split(",").map(Number);
                }
                callback();
            },
            beforeUpdate(form, callback) {
                form.user_ids = form.user.join(",");
                form.remind_method = form.methods.join(",");
                callback(form);
            }
        }
    }
</script>

<style lang="less">

</style>
