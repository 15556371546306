import {getKeyTitle} from "@/common/js/tool";


export const maintenanceTypeOptions = [
    { key: 1, title: "半月" },
    { key: 2, title: "1月" },
    { key: 3, title: "2月" },
    { key: 4, title: "3月" },
    { key: 5, title: "半年" },
]

export function getMaintenanceTypeText (type) {
    return getKeyTitle(maintenanceTypeOptions, type);
}